import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade anything. No strings attached.'),
  bodyText: t('Trade any token on BNB Smart Chain in seconds, just by connecting your wallet.'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.smbswap.finance/smbswap/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: 'BNB', alt: t('BNB token') },
      { src: 'BTC', alt: t('BTC token') },
      { src: 'SELF', alt: t('SELF token') },
    ],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Earn passive income with crypto.'),
  bodyText: t('SMBSwap helps you help your SELF.'),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.smbswap.finance/smbswap/products/yield-farming',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/earn/',
    attributes: [
      { src: 'pie', alt: t('Pie chart') },
      { src: 'stonks', alt: t('Stocks chart') },
//       { src: 'folder', alt: t('Folder with self token') },
    ],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('SELF makes the world go round.'),
  bodyText: t(
    'The SELF token is the heart of the SMBSwap ecosystem. Buy it, farm it, spend it and stake it!',
  ),
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0x7a364484303B38Bce7B0ab60a20DA8F2F4370129&chainId=56',
    text: t('Buy SELF'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.smbswap.finance/smbswap/tokenomics/self',
    text: t('Learn'),
    external: true,
  },

  images: {
    path: '/images/home/self/',
    attributes: [
      { src: 'self', alt: t('SELF token') },
    ],
  },
})
