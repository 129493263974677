import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js'
import { masterChefV2ABI } from 'config/abi/masterchefV2'
import { getMasterChefV2Address } from 'utils/addressHelpers'
import { formatEther } from 'viem'
import { useContractRead } from 'wagmi'

const SELF_PER_BLOCK = 10
const masterChefAddress = getMasterChefV2Address()

export const useSelfEmissionPerBlock = (inView?: boolean) => {
  const { data: emissionsPerBlock } = useContractRead({
    abi: masterChefV2ABI,
    address: masterChefAddress,
    chainId: ChainId.BSC,
    functionName: 'SELFPerBlockToBurn',
    enabled: inView,
    select: (d) => {
      const burn = formatEther(d)
      return new BigNumber(SELF_PER_BLOCK).minus(burn).toNumber()
    },
  })

  return emissionsPerBlock
}