import { ReactElement, useMemo } from 'react'
import shuffle from 'lodash/shuffle'
import CompetitionBanner from '../CompetitionBanner'
import IFOBanner from '../IFOBanner'
import V3LaunchBanner from '../V3LaunchBanner'
import LotteryBanner from '../LotteryBanner'
// import BUSDLotteryBanner from '../BUSDLotteryBanner'
import PerpetualBanner from '../PerpetualBanner'
import FarmV3MigrationBanner from '../FarmV3MigrationBanner'
// import useIsRenderIfoBanner from './useIsRenderIFOBanner'
import useIsRenderLotteryBanner from './useIsRenderLotteryBanner'
// import useIsRenderBUSDLotteryBanner from './useIsRenderBUSDLotteryBanner'
// import useIsRenderCompetitionBanner from './useIsRenderCompetitionBanner'
import TradingRewardBanner from '../TradingRewardBanner'
import { PolygonZkEvmBanner } from '../PolygonZkEvmBanner'
import EthBanner from '../EthBanner'

interface IBannerConfig {
  shouldRender: boolean
  banner: ReactElement
}

/**
 * make your custom hook to control should render specific banner or not
 * add new campaign banner easily
 *
 * @example
 * ```ts
 *  {
 *    shouldRender: isRenderIFOBanner,
 *    banner: <IFOBanner />,
 *  },
 * ```
 */
export const useMultipleBannerConfig = () => {
  // const isRenderIFOBanner = useIsRenderIfoBanner()
  const isRenderLotteryBanner = useIsRenderLotteryBanner()
  // const isRenderBUSDLotteryBanner = useIsRenderBUSDLotteryBanner()
  // const isRenderCompetitionBanner = useIsRenderCompetitionBanner()

  return useMemo(() => {
    const NO_SHUFFLE_BANNERS: IBannerConfig[] = [
      { shouldRender: false, banner: <PolygonZkEvmBanner /> },
      { shouldRender: false, banner: <TradingRewardBanner /> },
      { shouldRender: false, banner: <V3LaunchBanner /> },
      { shouldRender: false, banner: <FarmV3MigrationBanner /> },      
      // { shouldRender: true, banner: <TradingRewardBanner /> },
      { shouldRender: false, banner: <EthBanner /> },
      { shouldRender: false, banner: <IFOBanner />,
      },
    ]

    const SHUFFLE_BANNERS: IBannerConfig[] = [
      { shouldRender: false, banner: <CompetitionBanner />, },
      { shouldRender: isRenderLotteryBanner, banner: <LotteryBanner />, },
      // { shouldRender: isRenderBUSDLotteryBanner, banner: <BUSDLotteryBanner />,  },
      { shouldRender: false, banner: <PerpetualBanner />, },
    ]
    return [...NO_SHUFFLE_BANNERS, ...shuffle(SHUFFLE_BANNERS)]
      .filter((bannerConfig: IBannerConfig) => bannerConfig.shouldRender)
      .map((bannerConfig: IBannerConfig) => bannerConfig.banner)
  // }, [isRenderIFOBanner, isRenderLotteryBanner, isRenderBUSDLotteryBanner, isRenderCompetitionBanner])
}, [isRenderLotteryBanner])
}
