import BigNumber from 'bignumber.js'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { formatEther } from 'viem'
import { getUnixTime, sub } from 'date-fns'
import { gql } from 'graphql-request'
import { GetStaticProps } from 'next'
import { SWRConfig } from 'swr'
import { getSELFVaultAddress } from 'utils/addressHelpers'
import { getSELFContract } from 'utils/contractHelpers'
import { getBlocksFromTimestamps } from 'utils/getBlocksFromTimestamps'
import { bitQueryServerClient, infoServerClient } from 'utils/graphql'
import { useSelfBusdPrice } from 'hooks/useBUSDPrice'
import Home from '../views/Home'

const IndexPage = ({ totalTx30Days, addressCount30Days, tvl }) => {
  return (
    <SWRConfig
      value={{
        fallback: {
          totalTx30Days,
          addressCount30Days,
          tvl,
        },
      }}
    >
      <Home />
    </SWRConfig>
  )
}

// IndexPage.snowEffect = true

// Values fetched from TheGraph and BitQuery jan 24, 2022
const txCount = 3162
const addressCount = 2530

const tvl = 11915

export const getStaticProps: GetStaticProps = async () => {
  const totalTxQuery = gql`
    query TotalTransactions($block: Block_height) {
      smbfactory(block: $block) {
        totalTransactions
      }
    }
  `

  const days30Ago = sub(new Date(), { days: 30 })

  const results = {
    totalTx30Days: txCount,
    addressCount30Days: addressCount,
    tvl,
  }


  try {
    const [days30AgoBlock] = await getBlocksFromTimestamps([getUnixTime(days30Ago)])

    if (!days30AgoBlock) {
      throw new Error('No block found for 30 days ago')
    }

    const totalTx = await infoServerClient.request<any>(totalTxQuery)
    const totalTx30DaysAgo = await infoServerClient.request<any>(totalTxQuery, {
      block: {
        number: days30AgoBlock.number,
      },
    })

    if (
      totalTx?.smbfactory?.totalTransactions &&
      totalTx30DaysAgo?.smbfactory?.totalTransactions &&
      parseInt(totalTx.smbfactory.totalTransactions) > parseInt(totalTx30DaysAgo.smbfactory.totalTransactions)
    ) {
      results.totalTx30Days =
        parseInt(totalTx.smbfactory.totalTransactions) -
        parseInt(totalTx30DaysAgo.smbfactory.totalTransactions)
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'production') {
      console.error('Error when fetching total tx count', error)
    }
  }


  const usersQuery = gql`
    query userCount($since: ISO8601DateTime, $till: ISO8601DateTime) {
      ethereum(network: bsc) {
        dexTrades(exchangeName: { in: ["Pancake", "Pancake v2"] }, date: { since: $since, till: $till }) {
          count(uniq: senders)
        }
      }
    }
  `

  // if (process.env.BIT_QUERY_HEADER) {
  //   try {
  //     const result = await bitQueryServerClient.request<any>(usersQuery, {
  //       since: days30Ago.toISOString(),
  //       till: new Date().toISOString(),
  //     })
  //     if (result?.ethereum?.dexTrades?.[0]?.count) {
  //       results.addressCount30Days = result.ethereum.dexTrades[0].count
  //     }
  //   } catch (error) {
  //     if (process.env.NODE_ENV === 'production') {
  //       console.error('Error when fetching address count', error)
  //     }
  //   }
  // }

  try {
    const result = await infoServerClient.request<any>(gql`
      query tvl {
        smbfactories(first: 1) {
          totalLiquidityUSD
        }
      }
    `)

    // const self = await (await fetch('https://farms-api.pancakeswap.com/price/cake')).json()
    // const { totalLiquidityUSD } = result.pancakeFactories[0]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const selfPriceBUsd = useSelfBusdPrice({ forceMainnet: true })
    const self = selfPriceBUsd ? new BigNumber(selfPriceBUsd.toSignificant(6)) : BIG_ZERO
    const { totalLiquidityUSD } = result.smbfactories[0]
    const selfVaultV2 = getSELFVaultAddress()
    const selfContract = getSELFContract()
    const totalSELFInVault = await selfContract.read.balanceOf([selfVaultV2])
     results.tvl = (parseFloat(formatEther(totalSELFInVault)) * self.toNumber()) + parseFloat(totalLiquidityUSD)
  } catch (error) {
    if (process.env.NODE_ENV === 'production') {
      console.error('Error when fetching tvl stats', error)
    }
  }

  return {
    props: results,
    revalidate: 60 * 60 * 24 * 30, // 30 days
  }
}

IndexPage.chains = []

export default IndexPage